import React from 'react'
import { Link } from 'react-router-dom'
import { any, objectOf } from 'prop-types'
import { routeToEmployee } from '../../constants/routes'

const PersonCard = ({ person }) => {
  const { title, position, img, id } = person
  const { x1, x2, x3 } = img

  return (
    <div className='team__list--item'>
      <figure className='team__list--image'>
        <Link className='btns btn-b' to={routeToEmployee(id)} title={title}>
          <img className='image' src={x1} srcSet={`${x2} 2x, ${x3} 3x`} alt={title} />
        </Link>
      </figure>
      <h2 className='team__list--title'>
        <Link to={routeToEmployee(id)} title='Dr. David Coghlan'>
          {title}
        </Link>
      </h2>
      <div className='team__list--subtitle'>{position}</div>
      <div className='team__list--link'>
        <Link className='btns' to={routeToEmployee(id)} title='Learn more'>
          Learn more <i className='icons i22x10 i-arrow_right' />
        </Link>
      </div>
    </div>
  )
}

PersonCard.propTypes = { person: objectOf(any).isRequired }

export default PersonCard
