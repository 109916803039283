import React from 'react'
import StaticPage from '../../StaticPage'

// images
import aboutUs from '../../../assets/img/aboutus.jpg'
import aboutUs_2x from '../../../assets/img/aboutus@2x.jpg'

import aboutUs_3x from '../../../assets/img/aboutus@3x.jpg'
import aboutUs_M from '../../../assets/img/aboutus_m.jpg'
import aboutUs_M2x from '../../../assets/img/aboutus_m@2x.jpg'
import aboutUs_M3x from '../../../assets/img/aboutus_m@3x.jpg'

export default function AboutUs() {
  return (
    <StaticPage pageClassName='aboutus__page'>
      <div className='site__width'>
        <h1 className='page__title'>About us</h1>
        <div className='page__subtitle'>More about us and our team</div>
        <div className='page__image'>
          <img className='mobile' src={aboutUs_M} srcSet={`${aboutUs_M2x} 2x, ${aboutUs_M3x} 3x`} alt='About Us' />
          <img className='desktop' src={aboutUs} srcSet={`${aboutUs_2x} 2x, ${aboutUs_3x} 3x`} alt='About Us' />
        </div>
        <div className='page__content'>
          <div className='logo'>
            <i className='icons logo' />
          </div>
          <p className='small__text'>
            We are a consultant led diagnostic allergy testing service established in 2013. We believe allergy testing
            should be affordable and available to both children and adults.
          </p>
          <h2>We provide</h2>
          <ul>
            <li>Allergy testing for Kids (from 6 months of age) and Adults,</li>
            <li>Same day results,</li>
            <li>Direct access for patients,</li>
            <li>Management advise to patients,</li>
            <li>Adrenaline Pen training and advise.</li>
          </ul>
        </div>
      </div>
    </StaticPage>
  )
}
