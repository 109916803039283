import React, { useState } from 'react'
import { connect } from 'react-redux'
import { any, func, objectOf } from 'prop-types'
import moment from 'moment'
import BookingForm from './components/BookingForm'
import StaticPage from '../../../components/StaticPage'
import { ROUTE_TO_ROOT } from '../../../constants/routes'
import ConfirmationInfo from './components/ConfirmPage'
import { createPatientAction, getPatientAction, initiatePaymentAction } from '../actions'
import { errorNotificationAction, successNotificationAction } from '../../Notification/actions'

const BookingFormPage = ({
  bookingData,
  history,
  createPatient,
  patientData,
  getPatient,
  initiatePayment,
  successNotification,
  errorNotification,
}) => {
  const [isConfirmStep, setConfirmStep] = useState(false)

  if (!bookingData.id) history.push(ROUTE_TO_ROOT)

  const { is_video_call, id } = bookingData

  const onFormSubmit = async ({
    firstName,
    surname,
    email,
    birthDate,
    isChild,
    parentFirstName,
    parentSurname,
    tel,
    GPReferal,
    GPName,
    GPAddress,
    GPEmail,
    GPAttachment,
    testReason,
  }) => {
    const formData = new FormData()
    formData.append('patient[first_name]', firstName)
    formData.append('patient[last_name]', surname)
    formData.append('patient[email]', email)
    formData.append('patient[dob]', moment(birthDate).format('YYYY-MM-DD'))
    formData.append('patient[is_adult]', !isChild)
    formData.append('patient[guardian_first_name]', parentFirstName)
    formData.append('patient[guardian_last_name]', parentSurname)
    formData.append('patient[phone]', tel)
    formData.append('patient[is_referral]', GPReferal === 'yes')
    formData.append('patient[consultant_name]', GPName)
    formData.append('patient[consultant_addres]', GPAddress)
    formData.append('patient[consultant_email]', GPEmail)
    formData.append('patient[allergens]', testReason)
    formData.append('patient[referral_letter]', GPAttachment)
    formData.append('patient[session_id]', id)

    createPatient(formData)
      .then(({ value }) => getPatient(value.data.patient.id))
      .then(() => setConfirmStep(true))
      .catch((e) => console.error(e))
  }

  const onBookingConfirm = async ({ stripe, data: { price, patientId, sessionId } }) => {
    try {
      const data = {
        customer_email: stripe.email,
        customer_token: stripe.id,
        tok_mastercard: stripe.card.id,
        price,
        patient_id: patientId,
        session_id: sessionId,
      }

      await initiatePayment(data)

      successNotification(
        `${is_video_call
          ? 'You have successfully booked for the Video Call Appointment.'
          : 'You have successfully booked for the Allergy Test Appointment.'
        }`
      )
      history.push(ROUTE_TO_ROOT)
    } catch (e) {
      errorNotification(e.response.data.error_messages, 5)
      console.error(e)
    }
  }

  return (
    <StaticPage pageClassName='booking__page'>
      <div className='site__width'>
        <h1 className='page__title'>{is_video_call ? 'Book A Video Consultation' : 'Book an Allergy Test'}</h1>
        <div className='booking__block'>
          {!isConfirmStep ? (
            <div className='booking__form'>
              <div className='booking__form--cencel'>
                REQUEST AN APPOINTMENT
                <button className='btns btn-b' type='button' onClick={history.goBack}>
                  <i className='icons i24x24 i-close_white' />
                </button>
              </div>
              <div className='booking__form--bg'>
                <BookingForm bookingData={bookingData} onFormSubmit={onFormSubmit} />
              </div>
            </div>
          ) : (
            <ConfirmationInfo patientData={patientData} onBookingConfirm={onBookingConfirm} history={history} />
          )}
        </div>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({ booking: { bookingData, patientData } }) => ({ bookingData, patientData })

const mapDispatchToProps = (dispatch) => ({
  createPatient: (data) => dispatch(createPatientAction(data)),
  getPatient: (id) => dispatch(getPatientAction(id)),
  initiatePayment: (data) => dispatch(initiatePaymentAction(data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message, timeout) => dispatch(errorNotificationAction(message, timeout)),
})

BookingFormPage.propTypes = {
  createPatient: func.isRequired,
  getPatient: func.isRequired,
  initiatePayment: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
  bookingData: objectOf(any).isRequired,
  patientData: objectOf(any).isRequired,
  history: objectOf(any).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingFormPage)
