import typeToReducer from 'type-to-reducer'
import { LOGIN, LOGOUT } from './constants'

export const initialState = {
  inProcess: false,
  user: {},
  token: {},
  errors: [],
}

export default typeToReducer(
  {
    [LOGIN]: {
      START: (state, action) => {
        return {
          ...state,
          inProcess: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          user: action.payload.data.data.admin_user,
          token: action.payload.data.data.token,
          inProcess: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          inProcess: false,
        }
      },
    },
    [LOGOUT]: {
      SUCCESS: (state, action) => {
        return {
          ...state,
          user: {},
          token: {},
        }
      },
    },
  },
  initialState
)
