import React from 'react'

const AsthmaAllergyInfo = () => {
  return (
    <div className='allergyTypes__info--item'>
      <h2>Asthma</h2>
      <p>
        Asthma is a condition that primarily affects the tubes responsible for carrying air in and out of the lungs,
        known as the airways. Asthma occurs when the airways become over sensitive to factors that would not usually be
        problematic such as, dust or cold air. The airway narrows as a result of tightening of the muscles around the
        airway wall and it becomes difficult for air to flow in and out. The breathing passages become clogged with sticky
        mucous which is produced as the lining of the airway gets swollen.
      </p>
    </div>
  )
}

export default AsthmaAllergyInfo
