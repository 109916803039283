import React from 'react'
import StaticPage from '../../StaticPage'
import FaqList from './components/FaqList'
import { FAQS } from '../../../constants/companyData'

const FaqPage = () => {
  return (
    <StaticPage pageClassName='faqs__page'>
      <div className='site__width'>
        <h1 className='page__title'>FAQs</h1>
        <div className='page__content'>
          <FaqList items={FAQS} />
        </div>
      </div>
    </StaticPage>
  )
}

export default FaqPage
