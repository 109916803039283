import typeToReducer from 'type-to-reducer'
import { CREATE_ARTICLE, GET_BLOGS, UPDATE_ARTICLE } from './constants'

export const initialState = {
  blogsLoading: false,
  articleLoading: false,
  blogs: [],
  article: null,
  pages: 0,
  count: 0,
  errors: [],
}

export default typeToReducer(
  {
    [GET_BLOGS]: {
      START: (state) => {
        return {
          ...state,
          blogsLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          blogs: action.payload.data && action.payload.data.blogs,
          pages: action.payload.data && action.payload.data.pages,
          count: action.payload.data && action.payload.data.count,
          blogsLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          blogsLoading: false,
        }
      },
    },
    [CREATE_ARTICLE]: {
      START: (state) => {
        return {
          ...state,
          articleLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          articleLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          articleLoading: false,
        }
      },
    },
    [UPDATE_ARTICLE]: {
      START: (state) => {
        return {
          ...state,
          articleLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          articleLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          articleLoading: false,
        }
      },
    },
  },
  initialState
)
