import React from 'react'
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps'

const MapComponent = withScriptjs(
  withGoogleMap((props) => {
    const { zoom, coordinates, markers } = props
    return (
      <GoogleMap defaultZoom={zoom} defaultCenter={coordinates}>
        {markers.map((marker, index) => (
          <Marker key={index} position={marker} />
        ))}
      </GoogleMap>
    )
  })
)

export default MapComponent
