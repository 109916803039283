import React from 'react'

const DustAllergyInfo = () => {
  return (
    <div className='allergyTypes__info--item'>
      <h2>House Dust Mite Allergy</h2>
      <h3>What are dust mites?</h3>
      <ul>
        <li>House dust mites are microscopic creatures which cannot be visualized by the naked eye.</li>
        <li>They live in all our homes and feed on dead skin cells.</li>
        <li>
          They are most commonly found in warm, damp areas where dust containing dead skin cells gather e.g. pillows,
          mattresses, soft toys, carpets and soft furnishings.
        </li>
        <li>The mites are harmless to most people however they can cause allergic symptoms in some.</li>
      </ul>
      <h3>Signs/Symptoms</h3>
      <ul>
        <li>
          Symptoms are caused by breathing in substances known as allergens which are found in the mites droppings.
        </li>
        <li>Droppings are tiny therefore become airborne and can be breathed in.</li>
        <li>
          When these allergens touch the skin or are breathed in, the immune system reacts abnormally by causing the
          body to release a chemical called histamine.
        </li>
        <li>
          People with house dust mite allergy are likely to have some or all of the following symptoms:
          <ul className='sub-list'>
            <li>Loss of sense of smell</li>
            <li>Sneezing</li>
            <li>Coughing</li>
            <li>Itching(eyes, nose and throat)</li>
            <li>Wheezing</li>
          </ul>
        </li>
      </ul>
      <h3>Management of house dust mite in the home</h3>
      <p>
        It is not possible to eradicate house dust mite from your home but you can make them very unwelcome therefore
        reducing symptoms through implementation of the following guidelines;
      </p>
      <ul>
        <li>Replace feather and down pillows with synthetic pillows.</li>
        <li>Buy hypoallergenic duvets, pillows and mattresses or hypoallergenic covers for these.</li>
        <li>Wash fabrics i.e. bed linen at a minimum of 60 degrees Celsius to kill house dust mites.</li>
        <li>Dust rooms thoroughly with a damp cloth as dry cloths let dust back into the air.</li>
        <li>Reduce number of items on which dust can build up e.g. ornaments, books, toys.</li>
        <li>
          Open windows regularly throughout the home, in particular in the kitchen and bathrooms during and after
          cooking or bathing, to reduce dampness.
        </li>
        <li>Where possible replace carpets with hard flooring.</li>
        <li>Keep soft toys to a minimum- wash regularly or freeze for 12 hours to kill dust mites.</li>
        <li>
          Use a high efficiency filtered vacuum cleaner without bags to vacuum soft furnishing and mattresses regularly.
        </li>
      </ul>
    </div>
  )
}

export default DustAllergyInfo
