import * as Yup from 'yup'

const PASSWORD_MIN_LENGTH = 5
const PASSWORD_MAX_LENGTH = 12

const REQUIRED_MESSAGE = 'Required'
const TO_SHORT_MESSAGE = 'Too Short!'
const TO_LONG_MESSAGE = 'Too Long!'

export const loginSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required(REQUIRED_MESSAGE),
  password: Yup.string()
    .min(PASSWORD_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(PASSWORD_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
})
