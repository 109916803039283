import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { string, elementType, bool } from 'prop-types'
import { ROUTE_TO_LOGIN } from '../../constants/routes'
import getToken from '../../utils/getToken'

function PrivateRoute({ component, path, exact }) {
  const accessAllowed = getToken()

  if (!accessAllowed) {
    return <Redirect to={ROUTE_TO_LOGIN} />
  }

  return <Route exact={exact} path={path} component={component} />
}

PrivateRoute.propTypes = {
  component: elementType.isRequired,
  path: string.isRequired,
  exact: bool,
}

export default PrivateRoute
