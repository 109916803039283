const USERNAME_MAX_LENGTH = 15
const MESSAGE_MAX_LENGTH = 300

const REQUIRED_MESSAGE = 'This field is required , please fill in.'

const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = REQUIRED_MESSAGE
  } else if (values.username.length > USERNAME_MAX_LENGTH) {
    errors.username = `Must be ${USERNAME_MAX_LENGTH} characters or less`
  }

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.message) {
    errors.message = REQUIRED_MESSAGE
  } else if (values.message.length > MESSAGE_MAX_LENGTH) {
    errors.agreement = `Must be ${MESSAGE_MAX_LENGTH} characters or less`
  }

  return errors
}

export default validate
