import React from 'react'
import StaticPage from '../../StaticPage'

export default function FormsPage() {
  return (
    <StaticPage withoutClassPage pageClassName='formsListLinks__page'>
      <div className='site__width'>
        <h1 className='page__title'>Forms</h1>
        <div className='page__subtitle'>Please find below a list of printable forms.</div>
        <div className='formsListLinks__list'>
          <div className='formsListLinks__list--item'>
            <a
              className='btns btn-i btn-red_b btn-appnt'
              href='/files/Child.pdf'
              title='Patient Information/Consent Form - Child'
              target='_blank'>
              Patient Information/Consent Form - Child
            </a>
          </div>
          <div className='formsListLinks__list--item'>
            <a
              className='btns btn-i btn-red_b btn-appnt'
              href='/files/Adult.pdf'
              title='Patient Information/Consent Form - Child'
              target='_blank'>
              Patient Information/Consent Form - Adult
            </a>
          </div>
          <div className='formsListLinks__list--item'>
            <a
              className='btns btn-i btn-red_b btn-appnt'
              href='/files/HouseDust.pdf'
              title='Patient Information/Consent Form - Child'
              target='_blank'>
              House Dust Mite Information Leaflet
            </a>
          </div>
          <div className='formsListLinks__list--item'>
            <a
              className='btns btn-i btn-red_b btn-appnt'
              href='/files/Referral.pdf'
              title='Patient Information/Consent Form - Child'
              target='_blank'>
              Health Professionals Referral Form
            </a>
          </div>
        </div>
      </div>
    </StaticPage>
  )
}
