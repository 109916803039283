import * as React from 'react'
import './_styles.css'
import { func } from 'prop-types'

const CreateSessionSlots = ({ onClickHandler }) => {
  return (
    <div className='createSession-container'>
      <p className='createSession-title'>Create new session slots</p>
      <p className='createSession-description'>
        You have not created any session slots for this day. <br /> Click on Add new sessions slots.
      </p>
      <button className='btns btn-i btn-red_b btn-appnt' type='button' onClick={onClickHandler}>
        Add new session slots
      </button>
    </div>
  )
}

CreateSessionSlots.propTypes = {
  onClickHandler: func.isRequired,
}

export default CreateSessionSlots
