import React from 'react'
import { bool, func } from 'prop-types'
import ContactForm from './components/ContactForm'
import StaticPage from '../../StaticPage'
import ContactLinks from './components/Links'
import MapComponent from '../../Map'
import { COORDINATES, GOOGLE_MAP_URL } from '../../../constants/companyData'

const ZOOM = 13

const ContactUs = ({ loading, onSubmit, errorNotification, successNotification }) => {
  const onSubmitHandler = ({ username, email, message }) => {
    const data = {
      full_name: username,
      email,
      message,
    }
    onSubmit(data)
      .then(() => successNotification('You have successfully sent us a message'))
      .catch((e) => {
        errorNotification()
        console.error(e)
      })
  }

  return (
    <StaticPage pageClassName='contactus__page' noFooter>
      <div className='site__width'>
        <h1 className='page__title'>Contact us</h1>
        <div className='page__subtitle'>Any questions or queries? Please let us know</div>
        <div className='page__content'>
          <ContactForm loading={loading} onSubmitHandler={onSubmitHandler} />
          <MapComponent
            isMarkerShow
            coordinates={COORDINATES.CENTER_OF_MAP}
            zoom={ZOOM}
            googleMapURL={GOOGLE_MAP_URL}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div className='contactus__map' />}
            mapElement={<div style={{ height: `100%` }} />}
            markers={[COORDINATES.BLACKROCK_CLINIC, COORDINATES.BEACON_HOSPITAL]}
          />
          <ContactLinks />
        </div>
      </div>
    </StaticPage>
  )
}

ContactUs.propTypes = {
  loading: bool.isRequired,
  onSubmit: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
}

export default ContactUs
