import React from 'react'

export default function Sprite() {
  return (
    <svg width='0' height='0' className='hidden'>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' id='logout'>
        <path d='M11.08 12.59L13.67 10H4V8h9.67l-2.59-2.59L12.5 4l5 5-5 5-1.42-1.41zM16 0a2 2 0 0 1 2 2v4.67l-2-2V2H2v14h14v-2.67l2-2V16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2C0 .89.89 0 2 0h14z' />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 18' id='filter'>
        <path
          fillRule='evenodd'
          d='M11.001 16.88c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0l-4.01-4.01a.989.989 0 0 1-.29-.83V7.75L.211 1.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4l-4.79 6.13v9.13zM3.041 2l3.96 5.06v5.52l2 2V7.05L12.961 2h-9.92z'
        />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 18 18' id='search'>
        <path
          fillRule='evenodd'
          d='M6.5 0A6.5 6.5 0 0 1 13 6.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5-1.5 1.5-5-5v-.79l-.27-.27A6.516 6.516 0 0 1 6.5 13a6.5 6.5 0 1 1 0-13zm0 2C4 2 2 4 2 6.5S4 11 6.5 11 11 9 11 6.5 9 2 6.5 2z'
        />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' id='search-clear'>
        <path
          fillRule='evenodd'
          d='M10 0c5.53 0 10 4.47 10 10s-4.47 10-10 10S0 15.53 0 10 4.47 0 10 0zm3.59 5L10 8.59 6.41 5 5 6.41 8.59 10 5 13.59 6.41 15 10 11.41 13.59 15 15 13.59 11.41 10 15 6.41 13.59 5z'
        />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 22 16' id='adduser'>
        <path
          fillRule='evenodd'
          d='M14 0a4 4 0 1 0 0 8 4 4 0 0 0 0-8zm0 1.9a2.1 2.1 0 1 1 0 4.2 2.1 2.1 0 0 1 0-4.2zM3 3v3H0v2h3v3h2V8h3V6H5V3H3zm11 6c-2.67 0-8 1.33-8 4v3h16v-3c0-2.67-5.33-4-8-4zm0 1.9c2.97 0 6.1 1.46 6.1 2.1v1.1H7.9V13c0-.64 3.1-2.1 6.1-2.1z'
        />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 20' id='export'>
        <path
          fillRule='evenodd'
          d='M10 0l6 6v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h8zm4 18V7H9V2H2v16h12zm-6-1l-4-4h2.5v-3h3v3H12l-4 4z'
        />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' id='sort-a-z'>
        <path d='M13.5 15H16l-3.5 3.5L9 15h2.5V6h2z' />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' id='sort-z-a'>
        <path d='M11.5 9.5H9L12.5 6 16 9.5h-2.5v9h-2z' />
      </symbol>
      <symbol viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' id='delete'>
        <path
          fillRule='evenodd'
          d='M14 27a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V15H14v12zm2.46-7.12l1.41-1.41L20 20.59l2.12-2.12 1.41 1.41L21.41 22l2.12 2.12-1.41 1.41L20 23.41l-2.12 2.12-1.41-1.41L18.59 22l-2.13-2.12zM23.5 12l-1-1h-5l-1 1H13v2h14v-2h-3.5z'
        />
      </symbol>
      <symbol viewBox='0 0 40 40' xmlns='http://www.w3.org/2000/svg' id='edit'>
        <path
          fillRule='evenodd'
          d='M28.71 15.043c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.37-.39-1.02-.39-1.41 0l-1.84 1.83 3.75 3.75 1.84-1.83zM11 25.253v3.75h3.75l11.06-11.07-3.75-3.75L11 25.252z'
        />
      </symbol>
      <symbol viewBox='0 0 22 20' xmlns='http://www.w3.org/2000/svg' id='filter_off'>
        <path
          fillRule='evenodd'
          d='M12.731 17.83l2.85-2.83-2.85-2.83 1.42-1.41 2.85 2.81 2.8-2.81 1.42 1.41-2.81 2.83 2.81 2.83-1.42 1.41-2.8-2.84-2.85 2.84-1.42-1.41zm-1.73-.95c.04.3-.06.62-.29.83a.996.996 0 0 1-1.41 0l-4.01-4.01a.989.989 0 0 1-.29-.83V7.75L.211 1.62a1 1 0 0 1 .17-1.4c.19-.14.4-.22.62-.22h14c.22 0 .43.08.62.22a1 1 0 0 1 .17 1.4l-4.79 6.13v9.13zM3.041 2l3.96 5.06v5.52l2 2V7.05L12.961 2h-9.92z'
        />
      </symbol>
      <symbol xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' id='date'>
        <path
          d='M19 4h-1V2h-2v2H8V2H6v2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2zm0 16H5V10h14v10zm0-12H5V6h14v2z'
          opacity='.7'
        />
      </symbol>
      <symbol viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg' id='close-circle'>
        <path d='M12 2c5.53 0 10 4.47 10 10s-4.47 10-10 10S2 17.53 2 12 6.47 2 12 2zm3.59 5L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41 15.59 7z' />
      </symbol>
    </svg>
  )
}
