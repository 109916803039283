import React from 'react'
import moment from 'moment'
import { any, bool, func, number, objectOf, shape, string } from 'prop-types'
import { ROUTE_TO_ROOT } from '../../../../../constants/routes'
import PaymentForm from '../../../../../components/PaymentForm'
import config from '../../../../../config'
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements
} from '@stripe/react-stripe-js'

const stripePromise = loadStripe(config.REACT_APP_STRIPE_PUBLIC_KEY);

const ConfirmPage = ({ patientData: { session, ...patient }, onBookingConfirm, history }) => {
  const { is_adult, first_name, last_name, dob, email, phone, id: patientId } = patient
  const { price, data: date, start_time, end_time, is_video_call: isVideo, id: sessionId, location } = session

  const priceInCents = price //* 100

  const normalizedStartTime = moment(start_time, 'HH"mm').format('LT')
  const normalizedEndTime = moment(end_time, 'HH"mm').format('LT')
  const normalizedDate = `${moment(date, 'DD/MM/YYYY').format(
    'MMMM DD, YYYY',
  )} at ${normalizedStartTime} - ${normalizedEndTime}`

  return (
    <div className='booking__confirm'>
      <div className='booking__confirm--cencel'>
        REQUEST AN APPOINTMENT
        <button className='btns btn-b'>
          <i className='icons i24x24 i-close_white' />
        </button>
      </div>
      <div className='booking__confirm--bg'>
        <div className='booking__confirmHeader'>
          <p>Please confirm that you would like to request the following appointment:</p>
          <h2 className='booking__confirmHeader--title'>
            {isVideo ? 'Video Consultation with Allergy Nurse Specialist' : ''}
          </h2>
          <div className='booking__confirmHeader--time'>
            {`${is_adult ? 'Adult' : 'Child'} appointment`}
            <br />
            {normalizedDate}
          </div>
          <div className='booking__confirmHeader--price'>Cost: € {price}</div>
          {!isVideo ? (
            <div className='booking__confirmHeader--location-info'>
              <h5>Clinic address:</h5>
              <p>{location}</p>
            </div>
          ) : null}
        </div>
        <div className='booking__confirmBody'>
          <div className='booking__confirmBody--row'>
            <div className='booking__confirmBody--title'>Patient First name:</div>
            <div className='booking__confirmBody--data'>{first_name}</div>
          </div>
          <div className='booking__confirmBody--row'>
            <div className='booking__confirmBody--title'>Patient Surname:</div>
            <div className='booking__confirmBody--data'>{last_name}</div>
          </div>
          <div className='booking__confirmBody--row'>
            <div className='booking__confirmBody--title'>Patient DOB:</div>
            <div className='booking__confirmBody--data'>{dob}</div>
          </div>
          <div className='booking__confirmBody--row'>
            <div className='booking__confirmBody--title'>Contact Email:</div>
            <div className='booking__confirmBody--data'>{email}</div>
          </div>
          <div className='booking__confirmBody--row'>
            <div className='booking__confirmBody--title'>Contact Phone:</div>
            <div className='booking__confirmBody--data'>{phone}</div>
          </div>
        </div>
        <div className='booking__confirmFooter'>
          <p>
            You have 15 mins to make the purchase. After this time has elapsed, the slot will become available to other
            users.
          </p>
          <Elements stripe={stripePromise}>
            <PaymentForm
              onSubmit={(stripe) => onBookingConfirm({ stripe, data: { price, patientId, sessionId } })}
              price={priceInCents}
              patient={patient}
            />
          </Elements>
          <button className='btns btn-i btn-white_b' type='button' onClick={() => history.push(ROUTE_TO_ROOT)}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}

ConfirmPage.propTypes = {
  patientData: shape({
    is_adult: bool.isRequired,
    first_name: string.isRequired,
    last_name: string.isRequired,
    dob: string.isRequired,
    email: string.isRequired,
    phone: string.isRequired,
    id: number.isRequired,
    session: shape({
      price: number.isRequired,
      data: string.isRequired,
      start_time: string.isRequired,
      end_time: string.isRequired,
      is_video_call: bool.isRequired,
      id: number.isRequired,
    }),
  }),
  onBookingConfirm: func.isRequired,
  history: objectOf(any),
}

export default ConfirmPage
