import React from 'react'
import PersonList from '../../PersonList'
import { OUR_ADVISORS } from '../../../constants/companyData'
import StaticPage from '../../StaticPage'

export default function OurAdvisors() {
  return (
    <StaticPage pageClassName='advisors__page'>
      <div className='site__width'>
        <h1 className='page__title'>Our Advisors</h1>
        <PersonList items={OUR_ADVISORS} />
      </div>
    </StaticPage>
  )
}
