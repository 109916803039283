import React from 'react'
import { arrayOf, object } from 'prop-types'
import FaqItem from './FaqItem'

const FaqList = ({ items }) => {
  return (
    <div className='faqs__list'>
      {items.map((item) => (
        <FaqItem item={item} key={item.id} />
      ))}
    </div>
  )
}

FaqList.propTypes = {
  items: arrayOf(object),
}

export default FaqList
