import React from 'react'
import StaticPage from '../../StaticPage'

// images
import allergySymptoms1 from '../../../assets/img/allergysymptoms-1.jpg'
import allergySymptoms1_2x from '../../../assets/img/allergysymptoms-1@2x.jpg'
import allergySymptoms1_3x from '../../../assets/img/allergysymptoms-1@3x.jpg'

import allergySymptoms1_M from '../../../assets/img/allergysymptoms-1_m.jpg'
import allergySymptoms1_2x_M from '../../../assets/img/allergysymptoms-1_m@2x.jpg'
import allergySymptoms1_3x_M from '../../../assets/img/allergysymptoms-1_m@3x.jpg'

import allergySymptoms2 from '../../../assets/img/allergysymptoms-2.jpg'
import allergySymptoms2_2x from '../../../assets/img/allergysymptoms-2@2x.jpg'
import allergySymptoms2_3x from '../../../assets/img/allergysymptoms-2@3x.jpg'

import allergySymptoms2_M from '../../../assets/img/allergysymptoms-2_m.jpg'
import allergySymptoms2_2x_M from '../../../assets/img/allergysymptoms-2_m@2x.jpg'
import allergySymptoms2_3x_M from '../../../assets/img/allergysymptoms-2_m@3x.jpg'

export default function AllergySymptomsPage() {
  return (
    <StaticPage pageClassName='allergySymptoms__page'>
      <div className='site__width'>
        <h1 className='page__title'>Allergy Symptoms</h1>
        <div className='page__content'>
          <figure className='page__image'>
            <img
              className='mobile'
              src={allergySymptoms1_M}
              srcSet={`${allergySymptoms1_M} 1x ${allergySymptoms1_2x_M} 2x, ${allergySymptoms1_3x_M} 3x`}
              alt='Allergy4All'
            />
            <img
              className='desktop'
              src={allergySymptoms1}
              srcSet={`${allergySymptoms1} 1x ${allergySymptoms1_2x} 2x, ${allergySymptoms1_3x} 3x`}
              alt='Allergy4All'
            />
          </figure>
          <div className='page__text'>
            <h6>Mild Reaction</h6>
            <h2>Symptoms of a mild allergic reaction include:</h2>
            <ul>
              <li>Rash</li>
              <li>Localized itching/swelling</li>
            </ul>
            <p>
              <small>
                <strong>Note:</strong> Mild to moderate allergic reactions may or may not precede anaphylaxis.
              </small>
            </p>
          </div>
          <figure className='page__image'>
            <img
              className='mobile'
              src={allergySymptoms2_M}
              srcSet={`${allergySymptoms2_M} 1x ${allergySymptoms2_2x_M} 2x, ${allergySymptoms2_3x_M} 3x`}
              alt='Allergy4All'
            />
            <img
              className='desktop'
              src={allergySymptoms2}
              srcSet={`${allergySymptoms2} 1x ${allergySymptoms2_2x} 2x, ${allergySymptoms2_3x} 3x`}
              alt='Allergy4All'
            />
          </figure>
          <div className='page__text'>
            <h6>Severe Reaction (Anaphylaxis)</h6>
            <h2>Symptoms of a severe allergic reaction include:</h2>
            <ul>
              <li>Generalised flushing of the skin and feeling unwell.</li>
              <li>Sudden feeling of weakness (drop in blood pressure).</li>
              <li>Difficulty swallowing or speaking.</li>
              <li>Sense of impending doom.</li>
              <li>Swelling of throat and mouth.</li>
              <li>Difficulty breathing, stridor or cough/wheeze.</li>
              <li>Collapse and unconsciousness.</li>
            </ul>
            <p className='recommendation-link'>
              <small>
                <a
                  href='http://ifan.ie/food-allergy-in-summary/managing-an-allergic-reaction-sample-emergency-plan/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  Treatment recommendation
                </a>
              </small>
            </p>
          </div>
        </div>
      </div>
    </StaticPage>
  )
}
