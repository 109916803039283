import { combineReducers } from 'redux'
import { reducer as notifications } from 'react-notification-system-redux'
import appReducer from './containers/App/reducer'
import blogReducer from './containers/Blog/reducer'
import bookingReducer from './containers/Booking/reducer'
import authReducer from './admin/containers/Auth/reducer'
import availabilityReducer from './admin/containers/Availability/reducer'
import appointmentReducer from './admin/containers/AllergyTest/reducer'
import adminBlogReducer from './admin/containers/Blog/reducer'

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  blog: blogReducer,
  booking: bookingReducer,
  appointment: appointmentReducer,
  availability: availabilityReducer,
  adminBlog: adminBlogReducer,
  notifications,
})
