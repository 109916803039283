import React from 'react'
import { any, objectOf } from 'prop-types'
import { Link, Redirect } from 'react-router-dom'
import StaticPage from '../../StaticPage'
import { ROUTE_TO_OUR_ADVISORS, ROUTE_TO_OUR_TEAM, ROUTE_TO_ROOT } from '../../../constants/routes'
import { OUR_TEAM, OUR_ADVISORS } from '../../../constants/companyData'

const EmployeeDetails = ({ match }) => {
  const getEmployee = (personId) => {
    if (!personId) {
      return undefined
    }
    return OUR_TEAM.find(({ id }) => id === personId) || OUR_ADVISORS.find(({ id }) => id === personId)
  }

  const person = getEmployee(match.params.id)

  if (!person) {
    return <Redirect to={ROUTE_TO_ROOT} />
  }

  const { title, position, role, img, description, link } = person
  const { x1, x2, x3 } = img

  const isAdvisor = role === 'advisor'
  const to = isAdvisor ? ROUTE_TO_OUR_ADVISORS : ROUTE_TO_OUR_TEAM
  const routeTitle = isAdvisor ? 'Back to advisors' : 'Back to team'
  return (
    <StaticPage pageClassName='staff__page' withoutClassPage>
      <div className='site__width'>
        <figure className='staff__image'>
          <img className='image' src={x1} srcSet={`${x2} 2x, ${x3} 3x`} alt={title} />
        </figure>
        <h1 className='staff__title'>{title}</h1>
        <div className='staff__subtitle'>{position}</div>
        <div className='staff__content'>
          {description.map((info, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <p key={key}>{info}</p>
          ))}
          {link ? (
            <a href={link.src} target='_blank' rel='noopener noreferrer' style={{ textDecoration: 'underline' }}>
              {link.title}
            </a>
          ) : null}
        </div>
        <div className='staff__link'>
          <Link className='btns' to={to} title={routeTitle}>
            {routeTitle} <i className='icons i22x10 i-arrow_right' />
          </Link>
        </div>
      </div>
    </StaticPage>
  )
}

EmployeeDetails.propTypes = { match: objectOf(any).isRequired }

export default EmployeeDetails
