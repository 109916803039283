import * as Yup from 'yup'

const NAME_MIN_LENGTH = 2
const NAME_MAX_LENGTH = 20
const TEL_MIN_LENGTH = 6
const TEL_MAX_LENGTH = 12
const MESSAGE_MIN_LENGTH = 10
const MESSAGE_MAX_LENGTH = 300

const REQUIRED_MESSAGE = 'This field is required , please fill in.'
const TO_SHORT_MESSAGE = 'Text is too short.'
const TO_LONG_MESSAGE = 'Text is too long.'

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

export default Yup.object().shape({
  firstName: Yup.string()
    .min(NAME_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(NAME_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  surname: Yup.string()
    .min(NAME_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(NAME_MAX_LENGTH, TO_LONG_MESSAGE)
    .required(REQUIRED_MESSAGE),
  isChild: Yup.bool(),
  parentFirstName: Yup.string()
    .min(NAME_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(NAME_MAX_LENGTH, TO_LONG_MESSAGE),
  parentSurname: Yup.string()
    .min(NAME_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(NAME_MAX_LENGTH, TO_LONG_MESSAGE),
  email: Yup.string().email('Mail is invalid.'),
  tel: Yup.string()
    .min(TEL_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(TEL_MAX_LENGTH, TO_LONG_MESSAGE)
    .matches(phoneRegExp, 'Phone number is invalid.')
    .required(REQUIRED_MESSAGE),
  GPName: Yup.string()
    .min(NAME_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(NAME_MAX_LENGTH, TO_LONG_MESSAGE)
    .when(['isChild', 'isVideoCall'], {
    is: false,
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  GPAddress: Yup.string()
    .min(MESSAGE_MIN_LENGTH, TO_SHORT_MESSAGE)
    .max(MESSAGE_MAX_LENGTH, TO_LONG_MESSAGE)
    .when(['isChild', 'isVideoCall'], {
    is: false,
    then: Yup.string().required(REQUIRED_MESSAGE),
  }),
  GPEmail: Yup.string().email('Mail is invalid.'),
  testReason: Yup.string().min(MESSAGE_MIN_LENGTH, TO_SHORT_MESSAGE).max(MESSAGE_MAX_LENGTH, TO_LONG_MESSAGE),
})
