import React from 'react'
import moment from 'moment'
import FilterIcon from '../admin/components/Icons/Filter'

export default [
  {
    name: <div className='table-header-item'>Title</div>,
    selector: 'title',
    sortable: true,
    grow: 2,
  },
  {
    name: <div className='table-header-item'>Time</div>,
    selector: (row) => moment(row.created_at).format('DD/MM/YYYY'),
    sortable: true,
    left: true,
  },
]
