import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import MainMenu from '../Nav'
import { routeToBookTest } from '../../constants/routes'
import { BOOKING_TYPE_TEST, BOOKING_TYPE_VIDEO } from '../../constants'

export default function Header() {
  const [showMenu, setShowMenu] = useState(false)

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const bodyEl = document.body

    return showMenu ? bodyEl.classList.add('overflow', 'overlay') : bodyEl.classList.remove('overflow', 'overlay')
  }, [showMenu])

  const menuIconHandler = () => {
    return showMenu ? setShowMenu(false) : setShowMenu(true)
  }

  return (
    <header className={`header__main ${showMenu ? 'show-menu' : ''}`}>
      <div className='site__width'>
        <div className='header__logo'>
          <Link className='icons btn-b logo' to='/' title='Home' />
        </div>
        <div className='header__nav'>
            <button className='header__humburger' type='button' onClick={menuIconHandler}>
              <i className={`icons i-humburger i24x24 ${showMenu ? 'i-close_red' : ''}`} />
            </button>

          <div className='header__menu'>
            <MainMenu />
            <div className='header__menu--btns'>
              <Link
                className='btns btn-b btn-red_b btn-appnt'
                to={routeToBookTest(BOOKING_TYPE_TEST)}
                title='Book an Allergy Test'>
                Book an Allergy Test
              </Link>
              <Link
                className='btns btn-b btn-white_b btn-cntct'
                to={routeToBookTest(BOOKING_TYPE_VIDEO)}
                title='Book a Video Consultation'>
                Book a Video Consultation
              </Link>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
