import React from 'react'

const GrassAllergyInfo = () => {
  return (
    <div className='allergyTypes__info--item'>
      <h2>Grass Pollen</h2>
      <h3>What is grass pollen allergy?</h3>
      <ul>
        <li>Grass pollen is known to cause a variety of different allergic reactions.</li>
        <li>Pollen is a fine powder produced by plants.</li>
        <li>It is carried through the air to help fertilise plants, flowers and trees.</li>
        <li>Allergies to one or to several types of pollen can occur.</li>
        <li>
          Grass pollen is highly present in the air in the late spring and early summer months however people with grass
          allergy can suffer for most of the year.
        </li>
      </ul>
      <h3>Signs/Symptoms</h3>
      <p>
        With pollen allergy the immune system reacts abnormally to the pollen by releasing chemicals which cause
        inflammation of the nose, throat, eyes and lungs, which is known as rhinitis or hayfever. Symptoms may include:
      </p>
      <ul>
        <li>A blocked/ runny nose</li>
        <li>Sneezing</li>
        <li>Coughing</li>
        <li>Itching (eyes, nose and throat)</li>
        <li>Wheezing</li>
      </ul>
      <h3>Management of grass pollen allergy</h3>
      <p>
        It is impossible to avoid grass and tree pollen completely. However, here are some simple steps to minimise
        exposure:
      </p>
      <ul>
        <li>
          In the summer months the pollen count is usually at its highest and most widespread in the early morning. You
          will be least affected indoors, at the seaside or in mountainous areas.
        </li>
        <li>Keep windows closed while asleep as early morning pollen may be very troublesome.</li>
        <li>Close car windows when driving and use air conditioning.</li>
        <li>Before taking in washing from drying outside, shake thoroughly.</li>
        <li>Sunglasses can be worn to limit the amount of pollen-eye exposure.</li>
        <li>Take note of the pollen count on the weather forecast before planning outdoor activities.</li>
      </ul>
    </div>
  )
}

export default GrassAllergyInfo
