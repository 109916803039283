import React, { useState } from 'react'
import { connect } from 'react-redux'
import { any, arrayOf, bool, func, object, objectOf, string } from 'prop-types'
import StaticPage from '../../../components/StaticPage'
import BookingSelect from '../../../components/BookingSelect'
import Calendar from '../../../components/Calendar'
import { bookPreliminarilyAction, getMonthSlotsAction, getSessionsAction, setBookingDataAction } from '../actions'
import { APPOINTMENT_TYPES, APPOINTMENT_TYPES_VIDEO } from '../../../constants/companyData'
import { BOOKING_TYPE_VIDEO } from '../../../constants'

const BookingTest = ({
  monthLoading,
  sessionsLoading,
  monthSlots,
  getMonthSlots,
  setBookingData,
  bookPreliminarily,
  getSessions,
  sessions,
  history,
  match,
}) => {
  const [age, setAge] = useState(null)

  const bookingType = match.params.id
  const isVideo = bookingType === BOOKING_TYPE_VIDEO

  const title = isVideo ? 'Book A Video Consultation' : 'Book an Allergy Test'

  return (
    <StaticPage pageClassName='booking__page'>
      <div className='site__width'>
        <h1 className='page__title'>{title}</h1>
        <div className='booking__block'>
          <BookingSelect setAge={setAge} options={isVideo ? APPOINTMENT_TYPES_VIDEO : APPOINTMENT_TYPES} />
          {age ? (
            <Calendar
              monthSlots={monthSlots}
              sessions={sessions}
              getMonthSlots={getMonthSlots}
              getSessions={getSessions}
              history={history}
              monthLoading={monthLoading}
              sessionsLoading={sessionsLoading}
              age={age}
              setBookingData={setBookingData}
              bookPreliminarily={bookPreliminarily}
              isVideo={isVideo}
            />
          ) : null}
        </div>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({ booking }) => ({
  monthLoading: booking.monthLoading,
  sessionsLoading: booking.sessionsLoading,
  monthSlots: booking.monthSlots,
  sessions: booking.sessions,
  preliminarilyBooked: booking.preliminarilyBooked,
  errors: booking.errors,
})

const mapDispatchToProps = (dispatch) => ({
  getMonthSlots: (data) => dispatch(getMonthSlotsAction(data)),
  getSessions: (data) => dispatch(getSessionsAction(data)),
  bookPreliminarily: (id) => dispatch(bookPreliminarilyAction(id)),
  setBookingData: (item) => dispatch(setBookingDataAction(item)),
})

BookingTest.propTypes = {
  monthLoading: bool.isRequired,
  sessionsLoading: bool.isRequired,
  monthSlots: arrayOf(string).isRequired,
  getMonthSlots: func.isRequired,
  getSessions: func.isRequired,
  setBookingData: func.isRequired,
  bookPreliminarily: func.isRequired,
  sessions: arrayOf(object).isRequired,
  errors: arrayOf(object),
  history: objectOf(any),
  match: objectOf(any),
}

export default connect(mapStateToProps, mapDispatchToProps)(BookingTest)
