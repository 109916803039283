import React from 'react'
import { Link } from 'react-router-dom'
import { bool, string } from 'prop-types'

const MainButton = ({ to, title, className, color = 'red', disabled }) => {
  return (
    <Link className={`btns btn-i btn-${color}_b btn-appnt ${className} ${disabled ? 'disabled' : ''}`} to={to}>
      {title}
    </Link>
  )
}

MainButton.propTypes = {
  to: string,
  title: string.isRequired,
  className: string,
  color: string,
  disabled: bool,
}

export default MainButton
