import React from 'react'
import { any, objectOf } from 'prop-types'

const PersonCard = ({ resource }) => {
  const { title, info, link, img } = resource
  const { x1, x2, x3 } = img

  return (
    <div className='allergyResources__list--item'>
      <a className='btns' href={link} title={title} target='_blank' rel='noopener noreferrer'>
        <figure className='allergyResources__list--image'>
          <img className='image' src={x1} srcSet={`${x1} 1x, ${x2} 2x, ${x3} 3x`} alt='Resource' />
        </figure>
        <h2 className='allergyResources__list--title'>{title}</h2>
        <div className='allergyResources__list--info'>{info}</div>
        <div className='allergyResources__list--link'>
          Learn more <i className='icons i22x10 i-arrow_right' />
        </div>
      </a>
    </div>
  )
}

PersonCard.propTypes = { resource: objectOf(any).isRequired }

export default PersonCard
