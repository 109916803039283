import React from 'react'
import { any, bool, objectOf } from 'prop-types'
import Icon from '../../../Icons/Edit'
import history from '../../../../../history'
import { routeToArticleAdmin, routeToBookingAdmin } from '../../../../../constants/routes'

const EditRowButton = ({ appointment, isBlog }) => {
  const onClickHandler = () => {
    const to = isBlog ? routeToArticleAdmin('edit') : routeToBookingAdmin('edit')
    history.push(to, appointment)
  }

  return (
    <button type='button' className='btns delete_btn' title='Edit' onClick={onClickHandler}>
      <Icon />
    </button>
  )
}

EditRowButton.propTypes = {
  isBlog: bool,
  appointment: objectOf(any).isRequired,
}

export default EditRowButton
