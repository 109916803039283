import { Editor } from 'react-draft-wysiwyg'
import React from 'react'
import { objectOf, any, func, string } from 'prop-types'

const TextEditor = ({ onStateChange, onContentChange, editorState, contentState, error }) => {
  return (
    <div className='form--column'>
      <div className='form--row form--row_textarea'>
        <Editor
          editorState={editorState}
          initialContentState={contentState}
          toolbar={{
            options: ['history', 'fontFamily', 'fontSize', 'inline', 'link', 'list', 'textAlign'],
            inline: { inDropdown: false },
            link: { defaultTargetOption: '_target' },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            history: { inDropdown: false },
          }}
          wrapperClassName='text-editor-wrapper'
          editorClassName='text-editor'
          onEditorStateChange={onStateChange}
          onContentStateChange={onContentChange}
        />
        {error ? <p className='validation-error content-validation-error'>Content is required</p> : null}
      </div>
    </div>
  )
}
TextEditor.propTypes = {
  onStateChange: func.isRequired,
  onContentChange: func.isRequired,
  editorState: objectOf(any),
  contentState: objectOf(any),
  error: string,
}

export default TextEditor
