import React, { PureComponent } from 'react'
import memoize from 'memoize-one'
import DataTable from 'react-data-table-component'
import { func, number, bool, arrayOf, object, string } from 'prop-types'
import ArrowDownward from '../Icons/ArrowDown'
import AddUser from '../Icons/AddPerson'
import AddArticle from '../Icons/AddArticle'
import ExportIcon from '../Icons/Export'
import DeleteRowButton from './components/DeleteRowButton'
import EditRowButton from './components/EditRowButton'
import AddUserButton from './components/AddUserButton'
import CellEditor from './components/CellEditor'
import SearchForm from '../SearchForm'
import ConfirmationModal from '../../../components/ConfirmationModal'
import { ROUTE_TO_AVAILABILITY_ADMIN, routeToArticleAdmin } from '../../../constants/routes'
import history from '../../../history'
import { downloadCsv } from '../../../utils/downloadBlob'

const customStyles = {
  rows: {
    style: {
      '&:hover': {
        fontSize: '14px',
        fontFamily: 'Roboto, sans-serif',
      },
    },
  },
}

// Custom  arrow icon for table header
const sortIcon = <ArrowDownward />
// Custom button for export data from table
// eslint-disable-next-line react/prop-types
const Export = ({ onExport }) => (
  <button type='button' onClick={(e) => onExport(e.target.value)} className='btns btn-b export_btn' title='Export data'>
    <ExportIcon />
  </button>
)

class TableList extends PureComponent {
  constructor(props) {
    super(props)
    this.state = { toggleCleared: false, rowToDelete: null, showModal: false }
  }

  // handleChange = ({ selectedRows }) => this.setState({ selectedRows })

  render() {
    const { toggleCleared, showModal, rowToDelete } = this.state
    const {
      searchHandler,
      deleteHandler,
      count,
      page,
      perPage,
      loading,
      onChangePageHandler,
      shouldResetPagination,
      changePerPageHandler,
      tableHeaderData,
      tableData,
      search,
      setSearch,
      isBlog,
    } = this.props

    const onExportHandler = () => {
      const { onExport, isVideo } = this.props
      onExport().then(({ value }) => downloadCsv([value.data], isVideo))
    }

    const onDeleteHandler = (id) => {
      this.setState({ showModal: false })
      deleteHandler(id)
    }

    const deleteAppointment = (row) => {
      this.setState({ rowToDelete: row, showModal: true })
    }
    // Here we add array of table header data with custom cell for editing buttons
    const columns = memoize((tableHeader) => [
      ...tableHeader,
      {
        cell: (row) => (
          <CellEditor className='cell--editor_menu'>
            <EditRowButton appointment={row} isBlog={isBlog} />
            <DeleteRowButton onDelete={() => deleteAppointment(row)} />
          </CellEditor>
        ),
        allowOverflow: true,
        button: true,
        width: '56px', // custom width for icon button
      },
    ])

    let actions
    if (isBlog) {
      // is Add blog button
      actions = (
        <CellEditor className='tabs--header_menu'>
          <AddUserButton isBlog={isBlog} onClickHandler={() => history.push(routeToArticleAdmin('create'))}>
            <AddArticle />
          </AddUserButton>
          <div />
        </CellEditor>
      )
    } else {
      actions = (
        <CellEditor className='tabs--header_menu'>
          <AddUserButton onClickHandler={() => history.push(ROUTE_TO_AVAILABILITY_ADMIN)}>
            <AddUser />
          </AddUserButton>
          <Export onExport={onExportHandler} />
        </CellEditor>
      )
    }

    const tableSearch = (
      <span>
        <SearchForm
          onSubmitHandler={searchHandler}
          count={count}
          search={search}
          setSearch={setSearch}
          isBlog={isBlog}
        />
      </span>
    )

    return (
      <div className={`table--list appointments--tables table--list_body ${isBlog ? 'blog-table' : ''}`}>
        <DataTable
          customStyles={customStyles}
          columns={columns(tableHeaderData)}
          data={tableData}
          title={tableSearch}
          highlightOnHover
          defaultSortField={isBlog ? 'created_at' : 'patient.session.data_payment'}
          defaultSortAsc={false}
          paginationDefaultPage={page}
          paginationResetDefaultPage={shouldResetPagination}
          actions={actions}
          sortIcon={sortIcon}
          // onSelectedRowsChange={this.handleChange}
          onChangePage={onChangePageHandler}
          clearSelectedRows={toggleCleared}
          // onRowClicked={this.handleRowClicked}
          currentPage={page}
          pagination
          paginationServer
          paginationPerPage={perPage}
          onChangeRowsPerPage={changePerPageHandler}
          paginationTotalRows={count}
          progressPending={loading}
        />
        {showModal && !isBlog ? (
          <ConfirmationModal
            handler={onDeleteHandler}
            hideModalHandler={() => this.setState({ showModal: false })}
            title='Delete this appointment ?'
            message='All information about this appointment will be lost.
                    Are you sure you want to delete this appointment?'
            mainButtonContent='Delete'
            target={rowToDelete}
            goal='delete_appointment'
          />
        ) : null}

        {showModal && isBlog ? (
          <ConfirmationModal
            handler={onDeleteHandler}
            hideModalHandler={() => this.setState({ showModal: false })}
            title='Delete this article ?'
            message='All information about this article will be lost.
                    Are you sure you want to delete this article?'
            mainButtonContent='Delete'
            target={rowToDelete}
            goal='delete_article'
          />
        ) : null}
      </div>
    )
  }
}

TableList.propTypes = {
  searchHandler: func.isRequired,
  deleteHandler: func.isRequired,
  count: number.isRequired,
  page: number.isRequired,
  perPage: number.isRequired,
  loading: bool,
  isVideo: bool,
  search: string.isRequired,
  onChangePageHandler: func.isRequired,
  setSearch: func.isRequired,
  onExport: func,
  shouldResetPagination: bool.isRequired,
  isBlog: bool,
  changePerPageHandler: func.isRequired,
  tableHeaderData: arrayOf(object).isRequired,
  tableData: arrayOf(object).isRequired,
}

export default TableList
