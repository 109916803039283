import React from 'react'
import { string, bool, any } from 'prop-types'
import Header from '../Header'
import AdminHeader from '../../admin/components/Header'
import SvgSprite from '../SvgSprite'
import Footer from '../Footer'

const StaticPage = ({ isAdmin, withoutClassPage, pageClassName, children, noFooter }) => {
  const isContactUsPage = pageClassName === 'contactus__page' // for fixing white space below footer
  return (
    <div className='page_wrapper' style={{ paddingTop: '98px', paddingBottom: isContactUsPage ? '0' : '250px' }}>
      <SvgSprite />
      {isAdmin ? <AdminHeader /> : <Header />}
      <div className={`content__main ${!withoutClassPage ? 'page' : ''} ${pageClassName}`}>{children}</div>
      {isAdmin || noFooter ? null : <Footer />}
    </div>
  )
}

StaticPage.propTypes = {
  withoutClassPage: bool,
  noFooter: bool,
  isAdmin: bool,
  pageClassName: string,
  children: any,
}

export default StaticPage
