import * as React from 'react'
import moment from 'moment'
import './_styles.css'
import { any, arrayOf, bool, func, object, objectOf, string } from 'prop-types'
import {
  ROUTE_TO_AVAILABILITY_ADMIN,
  ROUTE_TO_BOOKING_FORM_PAGE,
  ROUTE_TO_ROOT,
  routeToBookingAdmin,
} from '../../../../../constants/routes'

const SessionList = ({
  sessions,
  selectedDate,
  setBookingData,
  onShowAvailabilityList,
  history,
  age,
  bookPreliminarily,
  bookSessionByAdmin,
  isAdmin,
}) => {
  const bookingHandler = async (item) => {
    if (item.status !== 'available') return

    try {
      !isAdmin
        ? await bookPreliminarily(item.id)
        : await bookSessionByAdmin(item.id, {
            session: {
              status: 'pending',
            },
          })
      setBookingData({ ...item, age })
      history.push(!isAdmin ? ROUTE_TO_BOOKING_FORM_PAGE : routeToBookingAdmin('create'))
    } catch (e) {
      console.error(e)
      history.push(!isAdmin ? ROUTE_TO_ROOT : ROUTE_TO_AVAILABILITY_ADMIN)
    }
  }

  return (
    <div className='sessionlist-wrapper'>
      <div className='sessionlist-container'>
        <div className='sessionlist-header'>
          {!isAdmin ? (
            <p className='booking__time--title'>Available slots on {moment(selectedDate).format(' MMMM DD, YYYY')}</p>
          ) : null}
          {isAdmin ? (
            <div className='availabilitys__time--title clearfix'>
              <p>Check the free Appointments on this day</p>
              <button className='btns btn-i btn-edit' type='button' onClick={onShowAvailabilityList}>
                <svg className='icons icons40x40'>
                  <use xlinkHref='#edit' />
                </svg>
              </button>
            </div>
          ) : null}
        </div>

        <ul className='sessionlist-list'>
          {sessions.map((item) => {
            const startTime = moment(`${selectedDate}T${item.start_time}`, 'YYYY-MM-DDTHH:mm')

            const endTime = moment(`${selectedDate}T${item.end_time}`, 'YYYY-MM-DDTHH:mm')

            const isAvailable = item.status === 'available'
            const disabled = isAdmin ? startTime.isSameOrBefore(moment()) || !isAvailable : !isAvailable

            // eslint-disable-next-line no-nested-ternary
            const buttonName = isAvailable ? (isAdmin ? 'Available' : 'Book Appointment') : 'Unavailable'

            return (
              <li key={item.id} className={`sessionlist-item ${disabled ? 'disabled-sessionlist-item' : ''}`}>
                <div className='sessionlist-item-info'>
                  <p className='sessionlist-item-time'>
                    {startTime.format('LT')} - {endTime.format('LT')}
                  </p>
                  <p className='sessionlist-item-price'>Cost: € {item.price}</p>
                </div>

                <button
                  className={`btns btn-i btn-${item.status === 'available' ? 'red' : 'grey'}_b btn-appnt book-button 
                  ${disabled ? 'disabled' : ''}`}
                  type='button'
                  disabled={disabled}
                  onClick={() => bookingHandler(item)}>
                  {buttonName}
                </button>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

SessionList.propTypes = {
  sessions: arrayOf(object).isRequired,
  selectedDate: string.isRequired,
  setBookingData: func.isRequired,
  onShowAvailabilityList: func.isRequired,
  bookPreliminarily: func,
  bookSessionByAdmin: func,
  history: objectOf(any).isRequired,
  age: string.isRequired,
  isAdmin: bool,
}

export default SessionList
