import React from 'react'
import PersonList from '../../PersonList'
import { OUR_TEAM } from '../../../constants/companyData'
import StaticPage from '../../StaticPage'

export default function OurTeam() {
  return (
    <StaticPage pageClassName='team__page'>
      <div className='site__width'>
        <h1 className='page__title'>Our Team</h1>
        <PersonList items={OUR_TEAM} />
      </div>
    </StaticPage>
  )
}
