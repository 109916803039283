import typeToReducer from 'type-to-reducer'
import { GET_MONTH_SLOTS, GET_SESSIONS, SET_BOOKING_DATA, GET_PATIENT, INITIATE_PAYMENT } from './constants'

export const initialState = {
  monthLoading: false,
  sessionsLoading: false,
  patientLoading: false,
  monthSlots: [],
  sessions: [],
  pages: 0,
  bookingData: {},
  patientData: {},
  errors: [],
  paymentInLoading: false,
  paymentInitiated: false,
  paymentErrors: [],
}

export default typeToReducer(
  {
    [GET_MONTH_SLOTS]: {
      START: (state, action) => {
        return {
          ...state,
          monthLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          monthSlots: action.payload.data.sessions,
          monthLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          monthLoading: false,
        }
      },
    },
    [GET_SESSIONS]: {
      START: (state, action) => {
        return {
          ...state,
          sessionsLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          sessions: action.payload.data.sessions,
          pages: action.payload.data.pages,
          sessionsLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          sessionsLoading: false,
        }
      },
    },
    [SET_BOOKING_DATA]: (state, action) => {
      return {
        ...state,
        bookingData: action.payload,
      }
    },
    [GET_PATIENT]: {
      START: (state, action) => {
        return {
          ...state,
          patientLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          patientData: action.payload.data.patient,
          patientLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          errors: [...state.errors, { message: action.payload.message, config: action.payload.config }],
          patientLoading: false,
        }
      },
    },
    [INITIATE_PAYMENT]: {
      START: (state, action) => {
        return {
          ...state,
          paymentInLoading: true,
        }
      },
      SUCCESS: (state, action) => {
        return {
          ...state,
          paymentInitiated: true,
          paymentInLoading: false,
        }
      },
      FAIL: (state, action) => {
        return {
          ...state,
          paymentInLoading: false,
        }
      },
    },
  },
  initialState
)
