import React from 'react'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './store'
import history from './history'
import App from './containers/App'
import Notification from './containers/Notification'

export default function () {
  return (
    <Provider store={store}>
      <Router history={history}>
        <App />
        <Notification style={{ 'z-index': 9999 }} />
      </Router>
    </Provider>
  )
}
