import React from 'react'
import { Redirect } from 'react-router-dom'
import { string } from 'prop-types'
import { ROUTE_TO_ROOT } from '../../../../constants/routes'
import OralAllergyInfo from './OralAllergy'
import RhinitisAllergyInfo from './RhinitisAllergy'
import GrassAllergyInfo from './GrassAllergy'
import ConjunctivitisAllergyInfo from './ConjunctivitisAllergy'
import FoodAllergyInfo from './FoodAllergy'
import AsthmaAllergyInfo from './AsthmaAllergy'
import DustAllergyInfo from './DustAllergy'

const TypeInfoWrapper = ({ page }) => {
  let content

  // TODO Complete routing
  switch (page) {
    case 'oral':
      content = <OralAllergyInfo />
      break

    case 'rhinitis':
      content = <RhinitisAllergyInfo />
      break

    case 'grass':
      content = <GrassAllergyInfo />
      break
    case 'conjunctivitis':
      content = <ConjunctivitisAllergyInfo />
      break

    case 'food':
      content = <FoodAllergyInfo />
      break

    case 'asthma':
      content = <AsthmaAllergyInfo />
      break

    case 'dust':
      content = <DustAllergyInfo />
      break

    default:
      content = <Redirect to={ROUTE_TO_ROOT} />
  }

  return (
    <div className='allergyTypes__info'>
      <div className='allergyTypes__info--list'>{content}</div>
    </div>
  )
}

TypeInfoWrapper.propTypes = {
  page: string.isRequired,
}

export default TypeInfoWrapper
