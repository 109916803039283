import React, { useState } from 'react'
import { useFormik } from 'formik'
import { bool, func } from 'prop-types'
import validate from './validate'

const ContactForm = ({ loading, onSubmitHandler }) => {
  const [agreementError, setAgreementError] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      username: '',
      email: '',
      agreement: false,
      message: '',
    },
    validate,
    onSubmit: async (values, { setSubmitting }) => {
      console.log(values);
      if(!values.agreement) return setAgreementError(true)

      try {
        setSubmitting(true)
        await onSubmitHandler(values)
      } catch (error) {
        console.error(error)
      }
    },
  })

  return (
    <form className='contactus__form form__default' onSubmit={formik.handleSubmit}>
      <div className='contactus__form--row form__row form__row--input'>
        <input
          className='contactus__form--input form__input form__input--text'
          name='username'
          id='username'
          type='text'
          placeholder='Full Name'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.username}
        />
        {formik.touched.username && formik.errors.username ? (
          <div className='validation-error'>{formik.errors.username}</div>
        ) : null}
      </div>
      <div className='contactus__form--row form__row form__row--input form__row'>
        <input
          className='contactus__form--input form__input form__input--text'
          name='email'
          id='email'
          type='text'
          placeholder='Your email'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className='validation-error'>{formik.errors.email}</div>
        ) : null}
      </div>
      <div className='contactus__form--row form__row form__row--input'>
        <p className='agreement-text'>
          We will not share or divulge any of your information with third parties.
          We will only use the information provided to contact you regarding your enquiry.
        </p>
        <input
          type='checkbox'
          name='agreement'
          id='agreement'
          onBlur={formik.handleBlur}
          onChange={(e) => {
            setAgreementError(false)
            return formik.handleChange(e)
          }}
          className='form__checkbox'
          checked={formik.values.agreement}
        />
        <label htmlFor='agreement' className='form__checkbox--label'>
          Yes
        </label>
        {agreementError ? (
          <div className='validation-error'>Agreement required</div>
        ) : null}

      </div>
      <div className='contactus__form--row form__row form__row--textarea'>
        <textarea
          className='contactus__form--input form__input form__input--textarea'
          name='message'
          id='message'
          placeholder='Your Message'
          onBlur={formik.handleBlur}
          onChange={formik.handleChange}
          value={formik.values.message}
        />
        {formik.touched.message && formik.errors.message ? (
          <div className='validation-error'>{formik.errors.message}</div>
        ) : null}
      </div>
      <div className='contactus__form--row form__row form__row--btns'>
        <button
          className='contactus__form--btns form__input--submit btns btn-i btn-red_b'
          name='submit'
          disabled={!formik.isValid || loading}>
          {loading ? 'Submitting...' : 'Send message'}
        </button>
      </div>
    </form>
  )
}

ContactForm.propTypes = {
  loading: bool.isRequired,
  onSubmitHandler: func.isRequired,
}

export default ContactForm
