import React from 'react'

// images
import hero from '../../assets/img/hero.jpg'
import hero_2x from '../../assets/img/hero@2x.jpg'
import hero_3x from '../../assets/img/hero@3x.jpg'
import hero_M from '../../assets/img/hero_m.jpg'
import hero_M2x from '../../assets/img/hero_m@2x.jpg'
import hero_M3x from '../../assets/img/hero_m@3x.jpg'

export default function HeroImage() {
  return (
    <span>
      <img className='mobile' src={hero_M} srcSet={`${hero_M} 1x, ${hero_M2x} 2x, ${hero_M3x} 3x`} alt='Allergy4All' />
      <img className='desktop' src={hero} srcSet={`${hero} 1x, ${hero_2x} 2x, ${hero_3x} 3x`} alt='Allergy4All' />
    </span>
  )
}
