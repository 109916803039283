import React, { useEffect, useState } from 'react'
import { objectOf, arrayOf, bool, number, func, string, object } from 'prop-types'
import { connect } from 'react-redux'
import TableList from '../../components/TableList'
import StaticPage from '../../../components/StaticPage'
import { deleteArticleAction, getBlogsAction } from './actions'
import tableHeaderData from '../../../utils/blogTableHeader'
import { DEFAULT_PER_PAGE_BLOGS } from './constants'
import Loader from '../../../components/Loader'
import { errorNotificationAction, successNotificationAction } from '../../../containers/Notification/actions'

const AdminBlog = (props) => {
  const { loading, blogs, getBlogs, deleteArticle, location, count, successNotification, errorNotification } = props

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(DEFAULT_PER_PAGE_BLOGS)
  const [search, setSearch] = useState('')
  const [shouldResetPagination, setShouldResetPagination] = useState(false)

  // Request for search article by title
  // eslint-disable-next-line no-shadow
  const searchArticleHandler = ({ search }) => getBlogs({ title: search })

  const deleteArticleHandler = async (id) => {
    try {
      const data = { page, per_page: perPage }

      await deleteArticle(id)
      await getBlogs(data)
      successNotification('You have successfully deleted the article')
    } catch (e) {
      console.error(e)
      errorNotification()
    }
  }

  useEffect(
    // eslint-disable-next-line no-shadow
    () => {
      const data = { page, per_page: perPage }
      getBlogs(data)
    },
    [page, perPage, getBlogs]
  )

  const onChangePageHandler = async (_page) => {
    setPage(_page)
    setShouldResetPagination(true)
    const data = { page: _page, per_page: perPage }
    await getBlogs(data)
  }

  return (
    <StaticPage isAdmin noFooter pageClassName='appointments__page'>
      <div className='content__main appointments__page page'>
        <div className='site__width'>
          {loading ? (
            <Loader styles={{ margin: '20vh' }} />
          ) : (
            <div className='table--list appointments--tables'>
              <TableList
                tableData={blogs}
                tableHeaderData={tableHeaderData}
                deleteHandler={deleteArticleHandler}
                searchHandler={searchArticleHandler}
                onChangePageHandler={onChangePageHandler}
                loading={loading}
                count={count}
                page={page}
                search={search}
                setSearch={setSearch}
                perPage={perPage}
                changePerPageHandler={setPerPage}
                shouldResetPagination={shouldResetPagination}
                isBlog
              />
            </div>
          )}
        </div>
      </div>
    </StaticPage>
  )
}

const mapStateToProps = ({ adminBlog }) => ({
  blogLoading: adminBlog.blogsLoading,
  articleLoading: adminBlog.articleLoading,
  blogs: adminBlog.blogs,
  count: adminBlog.count,
  errors: adminBlog.errors,
})

const mapDispatchToProps = (dispatch) => ({
  getBlogs: async (data) => await dispatch(getBlogsAction(data)),
  deleteArticle: async (id) => await dispatch(deleteArticleAction(id)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
})

AdminBlog.propTypes = {
  loading: bool,
  blogs: arrayOf(object),
  count: number,
  location: objectOf(string),
  getBlogs: func.isRequired,
  deleteArticle: func.isRequired,
  successNotification: func.isRequired,
  errorNotification: func.isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminBlog)
