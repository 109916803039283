import React from 'react'
import { objectOf, any } from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { routeToArticle } from '../../../constants/routes'
import config from '../../../config'

const SHORT_CONTENT_SIZE = 200

const BlogItem = ({ article }) => {
  const {
    title,
    content,
    created_at,
    icon: { url },
    slug,
  } = article

  const cutContent = (allContent) => {
    if (allContent.length <= SHORT_CONTENT_SIZE) return allContent

    const shortContentAsArray = allContent.substr(0, SHORT_CONTENT_SIZE).split(' ')
    shortContentAsArray[shortContentAsArray.length - 1] = '...'

    return shortContentAsArray.join(' ')
  }

  const publishedAt = moment(created_at).format('ll')

  return (
    <div className='articles__list--item'>
      <div className='articles__list--content'>
        <div className='articles__list--date'>{publishedAt}</div>
        <h2 className='articles__list--title'>
          <Link to={routeToArticle(slug)} title={title}>
            {title}
          </Link>
        </h2>
        <div className='articles__list--text'>
          <div dangerouslySetInnerHTML={{ __html: cutContent(content) }} />
        </div>
      </div>
      {url ? (
        <figure className='articles__list--image'>
          <Link to={routeToArticle(slug)} title={title}>
            <img className='image' src={`${config.REACT_APP_UPLOADS_URL}${url}`} alt='ArticlesImage' />
          </Link>
        </figure>
      ) : null}
    </div>
  )
}

BlogItem.propTypes = {
  article: objectOf(any),
}

export default BlogItem
