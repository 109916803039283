import React from 'react'
import { any, bool, func } from 'prop-types'

const AddUserButton = ({ children, onClickHandler, isBlog }) => {
  return (
    <button
      type='button'
      className='btns btn-b adduser_btn'
      title={`${isBlog ? 'Add article' : 'Add user'}`}
      onClick={onClickHandler}>
      {children}
    </button>
  )
}

AddUserButton.propTypes = {
  children: any,
  isBlog: bool,
  onClickHandler: func.isRequired,
}

export default AddUserButton
