import { createAction } from 'redux-actions'

import {
  DELETE_SESSION_ADMIN,
  GET_SESSIONS_ADMIN,
  UPDATE_SESSIONS_ADMIN,
  BOOK_BY_ADMIN,
  CREATE_PATIENT,
  UPDATE_PATIENT,
  GET_RESULTS,
} from './constants'
import {
  getSessionsAdminApi,
  updateSessionsAdminApi,
  deleteSessionAdminApi,
  bookByAdminApi,
  createPatientByAdminApi,
  updatePatientByAdminApi,
  getResultsApi,
} from './api'

export const getSessionsAdminAction = createAction(GET_SESSIONS_ADMIN, async (data) => await getSessionsAdminApi(data))
export const createPatientByAdminAction = createAction(
  CREATE_PATIENT,
  async (data) => await createPatientByAdminApi(data)
)
export const updatePatientByAdminAction = createAction(
  UPDATE_PATIENT,
  async (id, data) => await updatePatientByAdminApi(id, data)
)

export const updateSessionsAdminAction = createAction(
  UPDATE_SESSIONS_ADMIN,
  async (data) => await updateSessionsAdminApi(data)
)
export const deleteSessionAdminAction = createAction(
  DELETE_SESSION_ADMIN,
  async (id) => await deleteSessionAdminApi(id)
)
export const getResultsAction = createAction(GET_RESULTS, async (patientId) => await getResultsApi(patientId))

export const bookByAdminAction = createAction(BOOK_BY_ADMIN, async (id, data) => await bookByAdminApi(id, data))
