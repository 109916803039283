import React from 'react'
import { string, object, arrayOf } from 'prop-types'
import SubmenuItem from './SubmenuItem'

const Submenu = ({ forItem, menuItems = [] }) => {
  return (
    <ul key={forItem} className='header__submenu--list'>
      {menuItems.map((item) => SubmenuItem(item))}
    </ul>
  )
}

Submenu.propTypes = {
  forItem: string.isRequired,
  menuItems: arrayOf(object).isRequired,
}

export default Submenu
