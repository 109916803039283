import React from 'react'
import { isMobile } from 'react-device-detect'
import StaticPage from '../../StaticPage'

export default function AllergyTestPage() {
  return (
    <StaticPage pageClassName='allergyTest__page'>
      <div className='site__width'>
        <h1 className='page__title'>What is Allergy an Test</h1>
        <div className='page__subtitle'>
          A skin prick test is a diagnostic method for the confirmation of allergies.
          <br /> The aim of an allergy test is to trigger a minor yet controlled allergic reaction.
        </div>
        <div className='page__content'>
          <figure className='page__video video__block'>
            <div className='btn-video'>
              <iframe
                src='https://player.vimeo.com/video/402875228'
                width={isMobile ? '345px' : '853px'}
                height={isMobile ? '193px' : '480px'}
                frameBorder='0'
                allow='autoplay; fullscreen'
                allowFullScreen
                title='Demo'
              />
            </div>
          </figure>
          <div className='page__text'>
            <h2>How is a Skin Prick Test carried out?</h2>
            <p>The test area must be clean, dry and free from eczema.</p>
            <p>The test area must also be free from any creams and ointments.</p>
            <p>
              Allergen solutions and controls are applied in droplets to the skin at an appropriate distance from each
              other.
            </p>
          </div>
          <div className='page__text'>
            <p>The top layer of skin is scratched using a lancet, through the droplets.</p>
            <p>Excess allergen is wiped from the skin and the reaction is read after 15 minutes.</p>
            <p>
              If the reaction is positive it presents itself as a small raised swelling/wheal which is measured using a
              special ruler.
            </p>
          </div>
        </div>
      </div>
    </StaticPage>
  )
}
