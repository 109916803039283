import React from 'react'
import { objectOf, any } from 'prop-types'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { routeToArticle } from '../../../../../constants/routes'
import config from '../../../../../config'

const ArticlesItem = ({ article }) => {
  const {
    title,
    published_at,
    icon: { url },
    id,
  } = article

  const publishedAt = moment(published_at).format('ll')

  return (
    <div className='articlesMore__list--item'>
      <figure className='articlesMore__list--image'>
        <Link className='btns btn-b' to={routeToArticle(id)} title={title}>
          <img
            className='image'
            src={`${config.REACT_APP_UPLOADS_URL}${url}`}
            alt='ArticleImage'
          />
        </Link>
      </figure>
      <h2 className='articlesMore__list--title'>
        <Link to={routeToArticle(id)} title={title}>
          {title}
        </Link>
      </h2>
      <div className='articlesMore__list--date'>{publishedAt}</div>
    </div>
  )
}

ArticlesItem.propTypes = {
  article: objectOf(any),
}

export default ArticlesItem
