import React from 'react'
import { string } from 'prop-types'
import { NavLink } from 'react-router-dom'
import Submenu from '../Nav/components/Submenu'
import {
  ROUTE_TO_FORMS,
  ROUTE_TO_ALLERGY_TYPES,
  ROUTE_TO_ALLERGY_SYMPTOMS,
  ROUTE_TO_ALLERGY_RESOURCES,
  ROUTE_TO_BLOG,
  ROUTE_TO_OUR_TEAM,
  ROUTE_TO_OUR_ADVISORS,
  ROUTE_TO_ABOUT_US,
} from '../../constants/routes'

const MenuItem = ({ title, path }) => {
  let submenu

  if (title === 'About us') {
    const menuItems = [
      {
        title: 'What we do',
        path: ROUTE_TO_ABOUT_US,
      },
      {
        title: 'Our Team',
        path: ROUTE_TO_OUR_TEAM,
      },
      {
        title: 'Our Advisors',
        path: ROUTE_TO_OUR_ADVISORS,
      },
    ]
    submenu = <Submenu forItem='about_us' menuItems={menuItems} />
  } else if (title === 'Patient Info') {
    const menuItems = [
      {
        title: 'Forms',
        path: ROUTE_TO_FORMS,
      },
      {
        title: 'Types of Allergies',
        path: ROUTE_TO_ALLERGY_TYPES,
      },
      {
        title: 'Allergy Symptoms',
        path: ROUTE_TO_ALLERGY_SYMPTOMS,
      },
      {
        title: 'Allergy Resources',
        path: ROUTE_TO_ALLERGY_RESOURCES,
      },
      {
        title: 'Blog',
        path: ROUTE_TO_BLOG,
      },
    ]

    submenu = <Submenu forItem='patientInfo' menuItems={menuItems} />
  }

  const unclickableEl = (
    <NavLink to='' className='btns' onClick={(e) => e.preventDefault()}>
      {title}
    </NavLink>
  )

  return (
    <li key={title} className='header__menu--item'>
      {path ? (
        <NavLink to={path} className={`btns ${title === 'FAQs' ? 'transform_none' : ''}`}>
          {title}
        </NavLink>
      ) : (
        unclickableEl
      )}
      {submenu}
    </li>
  )
}

MenuItem.propTypes = {
  title: string.isRequired,
  path: string.isRequired,
}

export default MenuItem
