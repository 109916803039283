import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { string, elementType, bool } from 'prop-types'
import { ROUTE_TO_ALLERGY_TEST_ADMIN } from '../../constants/routes'
import getToken from '../../utils/getToken'

function StrictlyPublicRoute({ component, path, exact }) {
  const accessAllowed = getToken()

  if (accessAllowed) {
    return <Redirect to={ROUTE_TO_ALLERGY_TEST_ADMIN} />
  }

  return <Route exact={exact} path={path} component={component} />
}

StrictlyPublicRoute.propTypes = {
  component: elementType.isRequired,
  path: string.isRequired,
  exact: bool,
}

export default StrictlyPublicRoute
