import React from 'react'
import Spinner from 'react-loader-spinner'
import { number, object, oneOfType, string } from 'prop-types'

const Loader = (props) => {
  const { type, color, height, width, styles, timeout } = props

  return (
    <div className='loader' style={styles}>
      <Spinner type={type} color={color} height={height} width={width} timeout={timeout} />
    </div>
  )
}

Loader.defaultProps = {
  type: 'TailSpin',
  color: '#656565',
  height: 70,
  width: 70,
  timeout: 0,
}

Loader.propTypes = {
  type: string,
  color: string,
  height: number,
  width: number,
  timeout: number,
  styles: oneOfType([string, object]),
}

export default Loader
