import React from 'react'
import { ROUTE_TO_ROOT, ROUTE_TO_ABOUT_US, ROUTE_TO_FAQ, ROUTE_TO_CONTACT_US } from '../../constants/routes'
import NavItem from '../NavItem'

const Nav = () => {
  const menuItems = [
    {
      title: 'Home',
      path: ROUTE_TO_ROOT,
    },
    {
      title: 'About us',
      path: '',
    },
    {
      title: 'Patient Info',
      path: '',
    },
    {
      title: 'FAQs',
      path: ROUTE_TO_FAQ,
    },
    {
      title: 'Contact Us',
      path: ROUTE_TO_CONTACT_US,
    },
  ]

  return <ul className='header__menu--list'>{menuItems.map((item) => NavItem(item))}</ul>
}

export default Nav
