import React from 'react'
import FilterIcon from '../admin/components/Icons/Filter'

const renderStatus = (row) => {
  if (row.amount) {
    return row.status === 'succeeded' ? 'Paid' : row.status.charAt(0).toUpperCase() + row.status.slice(1)
  }
  return 'Booked by admin'
}

export default [
  {
    name: <div className='table-header-item'>Patient name</div>,
    cell: (row) => <div>{`${row.patient.first_name} ${row.patient.last_name}`}</div>,
    sortable: true,
    grow: 2,
  },
  {
    name: <div className='table-header-item'>Appointment type</div>,
    selector: (row) => (row.patient.is_adult ? 'Adult' : 'Child'),
    grow: 2,
    sortable: true,
    center: true,
  },
  {
    name: <div className='table-header-item'>Date</div>,
    selector: 'patient.session.data',
    sortable: true,
    center: true,
  },
  {
    name: <div className='table-header-item'>Time</div>,
    selector: 'patient.session.start_time',
    sortable: true,
    center: true,
  },
  {
    name: <div className='table-header-item'>Payment information</div>,
    selector: renderStatus,
    sortable: true,
    grow: 2,
    center: true,
  },
  {
    name: <div className='table-header-item'>Registered</div>,
    selector: 'data_payment',
    sortable: true,
    center: true,
  },
  {
    name: <FilterIcon />,
    sortable: false,
    right: true,
  },
]
