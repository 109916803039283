import React from 'react'

const ContactLinks = () => {
  return (
    <div className='contactus__links'>
      <div className='our-info_wrapper'>
        <div className='contact_wrapper'>
          <div className='contactus__links--contacts'>
            <div className='contactus__links--title'>Contact us</div>
            <ul>
              <li>
                <a className='btns btn-i' href='tel: 086 0736297' title='Phone'>
                  <i className='icons i32x32 i-phone_rnd i-rnd' />
                  086 0736297
                </a>
              </li>
              <li>
                <a className='btns btn-i lowerCase' href='mailto: info@allergy4all.ie' title='Email'>
                  <i className='icons i32x32 i-email_rnd i-rnd' />
                  info@allergy4all.ie
                </a>
              </li>
            </ul>
          </div>
          <div className='contactus__links--social'>
            <div className='contactus__links--title'>Follow us</div>
            <ul>
              <li>
                <a
                  className='btns btn-b'
                  href='https://twitter.com/allergy4all?lang=en'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='icons i32x32 i-twitter_rnd i-rnd' />
                </a>
              </li>
              <li>
                <a
                  className='btns btn-b'
                  href='https://www.facebook.com/allergy4all/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='icons i32x32 i-facebook_rnd i-rnd' />
                </a>
              </li>
              <li>
                <a
                  className='btns btn-b'
                  href='https://www.instagram.com/_allergy4all_/'
                  target='_blank'
                  rel='noopener noreferrer'>
                  <i className='icons i32x32 i-instagram_rnd i-rnd' />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='footer__copyrights'>
          <div className='footer__copyrights--logo'>
            <i className='icons logo' />
          </div>
          <div className='footer__copyrights--text'>
            <p>© 2020 Allergy 4 All. All Rights Reserved.</p>
            <p>Designed by Devensis</p>
          </div>
        </div>
      </div>

      <div className='contactus__links--address'>
        <div className='contactus__links--text'>
          <div className='direction_wrapper'>
            <a
              href='https://goo.gl/maps/mdw78rzPpw72kKE78'
              target='_blank'
              rel='noopener noreferrer'
              title='Get Directions'>
              <i className='icons i32x32 i-geo_rnd i-rnd' />
            </a>
            <p>Get directions</p>
          </div>

          <p>Suite 23, Blackrock Clinic, Rock Road, Blackrock, Co Dublin</p>
        </div>
        <div className='contactus__links--text'>
          <div className='direction_wrapper'>
            <a
              href='https://g.page/BeaconHospital?share'
              target='_blank'
              rel='noopener noreferrer'
              title='Get Directions'>
              <i className='icons i32x32 i-geo_rnd i-rnd' />
            </a>
            <p>Get directions</p>
          </div>
          <p>Suite 36, Beacon for Kids, Beacon Hospital, Sandyford, Dublin 18</p>
        </div>
        <div className='contactus__links--text'>
          <div className='direction_wrapper'>
            <a
              href='https://g.page/BeaconHospital?share'
              target='_blank'
              rel='noopener noreferrer'
              title='Get Directions'>
              <i className='icons i32x32 i-geo_rnd i-rnd' />
            </a>
            <p>Get directions</p>
          </div>
          <p>Suite 12, Beacon Consultants Suites, Beacon Hospital, Sandyford, Dublin 18</p>
        </div>
      </div>
    </div>
  )
}

export default ContactLinks
