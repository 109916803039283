import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { bool, func, number, string } from 'prop-types'
import SearchIcon from '../Icons/Search'
import SearchClearIcon from '../Icons/SearchClear'

const SearchForm = ({ onSubmitHandler, count, search, setSearch, isBlog }) => {
  const [showMenu, setShowMenu] = useState(false)
  useEffect(() => {
    // eslint-disable-next-line no-undef
    const targetEl = document.querySelector('.tabs--header_search')

    return showMenu ? targetEl.classList.add('show-search') : targetEl.classList.remove('show-search')
  }, [showMenu])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      search,
    },
    onSubmit: async (values, { setSubmitting }) => {
      try {
        setSubmitting(true)
        const response = await onSubmitHandler(values)
        const wanted = response.value.config.params.search_transaction

        setSearch(wanted)
      } catch (error) {
        console.error(error)
      }
    },
  })
  const menuIconHandler = (clearFormMaybe) => {
    if (clearFormMaybe === 'clear') {
      formik.values.search = ''
      setSearch('')
      formik.handleSubmit()
    }
    return showMenu ? setShowMenu(false) : setShowMenu(true)
  }

  let searchCount
  if (isBlog) {
    searchCount = count === 1 ? `${count} article` : `${count} articles`
  } else {
    searchCount = count === 1 ? `${count} user` : `${count} users`
  }

  return (
    <div className='tabs--header_search clearfix'>
      <button type='button' className='tabs--header_search-show' onClick={menuIconHandler}>
        <SearchIcon />
      </button>
      <form className='tabs--header_search-form' onSubmit={formik.handleSubmit}>
        <input
          className='tabs--header_search-input'
          type='text'
          name='search'
          value={formik.values.search}
          onChange={formik.handleChange}
          placeholder={`${isBlog ? 'Search articles' : 'Search appointments'}`}
        />
        <button className='tabs--header_search-submit' type='submit' value=''>
          <SearchIcon />
        </button>
        <button type='button' className='tabs--header_search-clear' onClick={() => menuIconHandler('clear')}>
          <SearchClearIcon />
        </button>
      </form>
      <div className='tabs--header_search-count'>{searchCount}</div>
    </div>
  )
}

SearchForm.propTypes = {
  onSubmitHandler: func.isRequired,
  count: number,
  search: string.isRequired,
  setSearch: func.isRequired,
  isBlog: bool,
}

export default SearchForm
