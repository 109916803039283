const {
  REACT_APP_BASE_URL,
  REACT_APP_UPLOADS_URL,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_STRIPE_SECRET_KEY,
} = process.env

const config = {
  REACT_APP_BASE_URL,
  REACT_APP_UPLOADS_URL,
  REACT_APP_STRIPE_PUBLIC_KEY,
  REACT_APP_STRIPE_SECRET_KEY,
}

export default config
