import React from 'react'
import { number, func, bool } from 'prop-types'

const TableCell = (props) => {
  const { day, currentDay, isActive, isAdmin, isPast, onClickHandler, blanksQuantity, availableSessions } = props

  const handleOnLick = (e) =>
    availableSessions || (isAdmin && !isPast) ? onClickHandler(e, day, blanksQuantity) : false

  const activeClass = isActive ? 'active' : ''
  const unavailableSessionsClass = (!availableSessions && !isAdmin) || (isAdmin && isPast) ? 'disable' : '' // past days were disabled for admin only

  return (
    <td
      className={`calendar-day ${currentDay ? 'current' : ''} ${activeClass} ${unavailableSessionsClass} ${isAdmin && availableSessions ? 'bold' : ''}`}
      onClick={handleOnLick}>
      <span>{day}</span>
    </td>
  )
}

TableCell.propTypes = {
  day: number.isRequired,
  currentDay: bool.isRequired,
  blanksQuantity: number.isRequired,
  isActive: bool.isRequired,
  isAdmin: bool,
  isPast: bool.isRequired,
  onClickHandler: func.isRequired,
  availableSessions: number.isRequired,
}

export default TableCell
