import React from 'react'
import { string } from 'prop-types'
import { ALLERGY_TYPES } from '../../../../constants/companyData'
import TypeListItem from './TypeListItem'

const TypeList = ({ page }) => {
  const typeList = ALLERGY_TYPES.map(({ svg, activeSvg, title, id }) => {
    let isActive = false

    if (page === id) isActive = true

    return <TypeListItem key={title} Svg={svg} ActiveSvg={activeSvg} title={title} id={id} isActive={isActive} />
  })

  return <div className='allergyTypes__nav--list'>{typeList}</div>
}

TypeList.propTypes = {
  page: string.isRequired,
}

export default TypeList
